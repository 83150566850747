const themes = [
  {
    themeId: "trackify-ui-theme-1",
    name: "UI Theme I",
    primaryColor: "#FFCC70",
    secondaryColor: "#22668D",
    textColor: "#FFFFFF",
    textHover: "#FFFADD",
    backgroundColor: "#8ECDDD",
    border: "#9ACD32 dashed 2px",
    font: "Georgia",
  },
  {
    themeId: "trackify-ui-theme-2",
    name: "UI Theme II",
    primaryColor: "#5AC3B0",
    secondaryColor: "#FFBD58",
    textColor: "#FFFFFF",
    textHover: "#F9F9F9",
    backgroundColor: "#03999D",
    border: "#9ACD32 dashed 2px",
    font: "Georgia",
  },
  {
    themeId: "trackify-ui-theme-3",
    name: "UI Theme III",
    primaryColor: "#B5CB99",
    secondaryColor: "#186F65",
    textColor: "#FFFFFF",
    textHover: "#F9F9F9",
    backgroundColor: "#B5CB99",
    border: "none",
    font: "Arial",
  },
  {
    themeId: "trackify-ui-theme-4",
    name: "UI Theme IV",
    primaryColor: "#567189",
    secondaryColor: "#567189",
    textColor: "#FFFFFF",
    textHover: "#F9F9F9",
    backgroundColor: "#E3A857",
    border: "#CCCCCC solid 1px",
    font: "Verdana",
  },
  {
    themeId: "trackify-ui-theme-5",
    name: "UI Theme V",
    primaryColor: "#172D13",
    secondaryColor: "#CD7E59",
    textColor: "#FFFFFF",
    textHover: "#F9F9F9",
    backgroundColor: "#457373",
    border: "#FFCC00 solid 1px",
    font: "Times New Roman",
  },
  {
    themeId: "trackify-ui-theme-6",
    name: "UI Theme VI",
    primaryColor: "#00CCFF",
    secondaryColor: "#A66249",
    textColor: "#FFFFFF",
    textHover: "#F9F9F9",
    backgroundColor: "#5A6868",
    border: "#66CC66 solid 1px",
    font: "Tahoma",
  },
];
  
export { themes };
  